<template>
  <div class="pxp__header">
    <div class="header__logo">
      <img src="@/assets/images/pxp-logo.svg" alt="pagexpage logo" @click="goDashboard"/>
    </div>
    <div class="header__links">
      <template v-if="workspaceId !== '-'">
        <router-link
          :to="`/workspace/${workspaceId}/dashboard`"
          active-class="router-link-active"
          >Dashboard</router-link
        >
        <router-link
          :to="`/workspace/${workspaceId}/roadmap`"
          active-class="router-link-active"
          >Roadmap</router-link
        >
        <router-link
          :to="`/workspace/${workspaceId}/sprints`"
          active-class="router-link-active"
          >Sprints</router-link
        >
        <router-link
          :to="`/workspace/${workspaceId}/goals`"
          active-class="router-link-active"
          >Goals</router-link
        >
        <router-link
            :to="`/workspace/${workspaceId}/course`"
            active-class="router-link-active"
        >Course</router-link>
        <a href="https://pagexpage.com/phd-coaching/" target="_blank" rel="noopener">Coaching</a>
      </template>
    </div>
    <div class="header__spacer" />
    <div class="header__tools">
      <template v-if="subscription.plan === 'test'">
        <div class="trial">
          <IconNew name="time" class="icon"/>

          <p class="last">{{remainingDays}} days left of your trial. <span class="upgrade" @click="goToBilling">Upgrade now</span></p>
        </div>
      </template>

      <!--
      <router-link to="/">
        <Icon :size="16" :icon="notifyIcon" />
      </router-link>
      <router-link to="/">
        <Icon :size="16" :icon="questionIcon" />
      </router-link>
      <router-link to="/">
        <Icon :size="16" :icon="favoriteIcon" />
      </router-link>
      -->
    </div>
    <div class="uk-inline header__account">
      <Avatar :full-name="user.fullName" :src="user.avatarThumbnail" :size="32" :id="'header-avatar'" />
      <a class="user-name__link">{{ user.shortFullName }}</a>

      <div
        class="header__account-dropdown"
        uk-dropdown="offset: 16; animation: uk-animation-slide-bottom-small; duration: 300; mode:hover; delay-hide: 300;"
      >
        <ul class="uk-nav uk-dropdown-nav">
          <template v-if="workspaces && workspaces.owner && workspaces.collaborator &&
                          workspaces.owner.length + workspaces.collaborator.length > 2">
            <template v-if="workspaces.owner.length > 0">
              <p>My workspaces:</p>
              <template v-for="workspace in workspaces.owner">
                <li :class="workspaceId === workspace.id? 'active': ''"><router-link :to="`/workspace/${workspace.id}/${nowRoute}`">{{workspace.workName}}</router-link></li>
              </template>
            </template>
            <template v-if="workspaces.collaborator.length > workspaces.owner.length">
              <p>As collaborator:</p>
              <template v-for="workspace in workspaces.collaborator">
                <template v-if="workspace.owner.id !== user.id">
                  <li :class="workspaceId === workspace.id? 'active': ''"><router-link :to="`/workspace/${workspace.id}/${nowRoute}`">{{workspace.workName}}</router-link></li>
                </template>
              </template>
            </template>
            <li class="uk-nav-divider"></li>
          </template>
          <li><router-link :to="`/workspace/${workspaceId}/account/me`">Account</router-link></li>
<!--          <li><router-link :to="`/workspace/${workspaceId}/account/billing`">Billing</router-link></li>-->
          <li class="uk-nav-divider"></li>
          <li><a class="header__logout" @click="logoutClick">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="pxp__header-place"/>
</template>

<script>
import Icon from "@/components/UI-Kit/Icon";
import { Icon as IconClass } from "@/components/UI-Kit/Helper classes/icon";
import { inject, computed } from "vue";
import Avatar from "@/components/UI-Kit/Avatar";
import { logout } from "@/setups/authSetup";
import { useRouter, useRoute } from "vue-router";
import IconNew from "@/components/UI-Kit/IconNew";
import moment from "moment"

const setup = function(props) {
  const router = useRouter();
  const user = inject("user");
  const subscription = inject("subscription");
  const workspaces = inject("workspaces");
  const route = useRoute();

  const notifyIcon = new IconClass(
    require("@/assets/images/notify.svg"),
    "notify",
    ""
  );
  const questionIcon = new IconClass(
    require("@/assets/images/question.svg"),
    "question",
    ""
  );
  const favoriteIcon = new IconClass(
    require("@/assets/images/favorite.svg"),
    "favorite",
    ""
  );

  const goDashboard = function() {
    router.push({
      name: 'Dashboard',
      params: {
        workspaceId: props.workspaceId
      }
    })
  }

  const logoutClick = function() {
    logout();
    router.push({
      name: "Auth"
    });
  };

  const remainingDays = computed(() => {
    const days = Math.ceil(
      moment.duration(
          Date.parse(subscription.value.lastUpdate) + 1209600000
          - Date.now())
          .asDays())
    return Math.max(days, 0)
  })

  const goToBilling = function () {
    router.push({
      name: "Billing",
      params: {
        workspaceId: props.workspaceId
      }
    })
  }

  const nowRoute = computed(() => {
    const path = route.path.split('/')
    path.shift()
    path.shift()
    path.shift()
    return path.join("/")
  })

  return {
    user,
    notifyIcon,
    questionIcon,
    favoriteIcon,
    logoutClick,
    goDashboard,
    goToBilling,
    subscription, remainingDays,
    workspaces,
    nowRoute
  };
};

export default {
  name: "Header",
  components: {IconNew, Avatar, Icon },
  props: {
    workspaceId: {
      required: true,
      type: String
    }
  },
  setup
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.pxp__header-place {
  height: 48px;
  width: 100%;
  position: relative;
}

.pxp__header {
  height: 48px;
  width: 100%;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  z-index: 1000;
  position: fixed;
  //grid-template-areas: "header__logo header__links header__tools header__account";
  box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.25);
}

.header__logo {
  align-self: center;
  img {
    width: 131px;
    max-width: 131px;
    height: 23px;
  }
  margin: 0 24px;
  cursor: pointer;
}

.header__tools {
  display: flex;
  align-self: center;
  justify-self: right;
  justify-content: right;
  flex-direction: row;
  column-gap: 24px;
  margin-right: 32px;
}

#header-avatar {
  width: 32px;
  height: 32px;
}

.header__links {
  grid-area: header__links;
  flex-direction: row;
  display: flex;
  justify-self: left;
  align-self: center;
  column-gap: 15px;
  justify-items: left;
  margin-left: 8px;

  a {
    font-weight: 600;
    text-decoration: none;
    color: #80869d;
    font-size: 14px;
    line-height: 16px;
    &:hover {
      text-decoration: none;
      color: #0cac60;
    }
    &.router-link-active {
      color: #0cac60;
      box-shadow: 0 3px white, 0 5px #0cac60;
    }
  }
}
.header__account {
  grid-area: header__account;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: right;
  margin-right: 32px;
  column-gap: 8px;
  white-space: pre;
  text-align: left;
}

.header__spacer {
  width: 100%;
}

.header__account-dropdown {
  border: 1px solid #bfc4d6;
  box-sizing: border-box;
  /* LIGHT / D300 - Modals */

  box-shadow: 0px 6px 10px rgba(35, 35, 32, 0.2);
  border-radius: 8px;
  background-color: white;
}

.user-name__link,
.user-name__link:active,
.user-name__link:hover,
.user-name__link:focus {
  outline: none;
  border: none;
  color: black;
  text-decoration: none;
}

.trial {
  display: flex;
  flex-direction: row;
  width: 291px;
  align-items: center;
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

.upgrade {
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: right;

  color: #0CAC60;

  opacity: 0.87;
}

li.active {
  font-weight: 500;
  a {
    color: $pxp-blue-added-color!important;
  }
}


</style>
