import {computed, ref, watch, reactive, provide, inject} from "vue"
import {getResearchQuestion, getTask} from "@/api/sprint";

function clearObject (obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            delete obj[key]
    }
}
function clearArray (arr) {
    arr.splice(0, arr.length)
}


const isResearchTasksTop = function (move) {
    return move.neighborTop === 'rq-top'
}

function getSortedResearch (rqs, tasks, date) {
    const research = {}
    const findResearchTop = function (items) {
        for (const id in items) {
            const item = items[id]
            const move = findMoveByDate(item, date)

            if (move === null)
                continue

            if (isResearchTasksTop(move))
                research[item.researchQuestion] = [item]

        }
    }

    findResearchTop(tasks)

    for (let id in research) {
        const column = research[id]

        if (!column || column.length === 0)
            continue

        let isColumnBottom = false

        while (!isColumnBottom) {
            const now = column[column.length - 1]
            const neighborBottom = findMoveByDate(now, date).neighborBottom

            if (neighborBottom === 'rq-bottom')
                isColumnBottom = true
            else
                column.push(getNeighbor(neighborBottom))
        }
    }

    return research
}

export const sortedResearchInitialization = function (props) {
    const tasks = inject('tasks')
    const rqs = inject('rqs')
    const date = inject('date')

    const sortedColumns = computed(() => status.value === "loading"? null: getSortedColumns(rqs, tasks, date.value()))
    const sortedResearch = computed(() => status.value === "loading"? null: getSortedResearch(rqs, tasks, date.value()))

}


export const init = function (props) {
    const tasks = reactive([])
    const rqs = reactive([])
    const status = ref('loading')
    const date = ref(() => (new Date()).toISOString())

    const initItems = async function (items, getter) {
        const response = await getter(props.workspaceId)
        items.splice(0, items.length, ...response.map(item => reactive(item)))
    }

    const init = async function () {
        status.value = 'loading'

        await Promise.all([
            initItems(rqs, getResearchQuestion),
            initItems(tasks, getTask)
        ])

        status.value = 'loaded'
    }

    const kanban = computed(() => {
        const list = {}
        tasks.forEach((item) => {
            list['tsk-' + item.id] = item
        })
        rqs.forEach((item) => {
            list['rq-' + item.id] = item
        })
    })

    /*const socket = inject('socket')
    const user = inject('user')
    socket.value.on('created_research-question', (data) => {
        const rq = data.data
        const authorId = data.author
        rq.sync = false
        rq.type = 'rq'
        if (authorId !== user.id) {
            rqs[rq.id] = rq
            fixMove('', '', rq.move[0].neighborTop, rq.move[0].neighborBottom, rq.move[0].date, 'rq-' + rq.id)
        }
    })
    socket.value.on('updated_research-question', (data) => {
        const rq = data.data
        const authorId = data.author

        if (authorId !== user.id) {
            rqs[rq.id].name = rq.name
            rqs[rq.id].details = rq.details
            rqs[rq.id].learnings = rq.learnings
            rqs[rq.id].plans = rq.plans
        }
    })
    socket.value.on('moved_research-question', (data) => {
        const rq = data.data
        const authorId = data.author

        if (authorId !== user.id) {
            const lastPlace = rqs[rq.id].move[rqs[rq.id].move.length - 1]
            const newPlace = rq.move[rq.move.length - 1]
            fixMove(lastPlace.neighborTop, lastPlace.neighborBottom, '', '', newPlace.date, 'rq-' + rq.id)
            rqs[rq.id].move = rq.move
            fixMove('', '', newPlace.neighborTop, newPlace.neighborBottom, newPlace.date, 'rq-' + rq.id)
        }
    })
    socket.value.on('created_task', (data) => {
        const task = data.data
        const authorId = data.author
        task.sync = false
        task.type = 'tsk'
        if (authorId !== user.id) {
            tasks[task.id] = task
            rqs[task.researchQuestion.id].tasks.push(task)
            fixMove('', '', task.move[0].neighborTop, task.move[0].neighborBottom, task.move[0].date, 'tsk-' + task.id)
        }
    })
    socket.value.on('updated_task', (data) => {
        const task = data.data
        const authorId = data.author

        if (authorId !== user.id) {
            tasks[task.id].name = task.name
        }
    })
    socket.value.on('moved_task', (data) => {
        const task = data.data
        const authorId = data.author

        if (authorId !== user.id) {
            const lastPlace = tasks[task.id].move[tasks[task.id].move.length - 1]
            const newPlace = task.move[task.move.length - 1]
            fixMove(lastPlace.neighborTop, lastPlace.neighborBottom, '', '', newPlace.date, 'tsk-' + task.id)
            tasks[task.id].move = task.move
            fixMove('', '', newPlace.neighborTop, newPlace.neighborBottom, newPlace.date, 'tsk-' + task.id)
        }
    })*/

    init()
    watch (() => props.workspaceId, () => {
        init()
    })

    provide('rqs', rqs)
    provide('tasks', tasks)
    provide('items', items)
    provide('date', date)
    provide('loadResearch', status)
}
